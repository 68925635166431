import React from 'react';
import { graphql } from 'gatsby';
import CoverImageTitle from '../components/coverImageTitle';
import ArticlewithCTA from '../components/articleWithCTA';
import CoverImage from '../components/coverImage';
import TestimonialSection from '../components/TestimonialSection';
import Slider from '../components/slider';

const CaseStudy = (props) => {
  const {
    data: {
      wordpressPage: {
        acf: {
          color_template,
          title,
          background_image: {
            localFile: {
              childImageSharp: {
                fluid: backgroundImage,
              },
            },
          },
          article_with_background: {
            title: articleTitle,
            text: articleText,
            background_image: {
              localFile: {
                childImageSharp: {
                  fluid: articleBackgroundImage,
                },
              },
            },
          },
          article_without_background: {
            title: secondArticleTitle,
            text: secondArticleText,
          },
          article_log: {
            image: {
              localFile: {
                childImageSharp: {
                  fluid: imageSerctionImg,
                },
              },
            },
            item_section,
          }, 
          testimonial_section: {
            slide,
          },
          slider_at_the_bottom: {
            section_title: bottomSlideTitle,
            section_image: {
              localFile: {
                childImageSharp: {
                  fluid: bottomSlideImage,
                },
              },
            },
            item_slider: bottomSlide,
          },
        },
      },
    },
  } = props; 

  return (
    <>

      <CoverImageTitle title={title} fluid={backgroundImage} />
      <ArticlewithCTA title={articleTitle} text={articleText} fluid={articleBackgroundImage} />
      <ArticlewithCTA title={secondArticleTitle} text={secondArticleText} />
      <CoverImage fluid={imageSerctionImg} />
      {item_section.map((item) => (
        <ArticlewithCTA
          style={{ height: 'auto' }}
          column
          key={item.title}
          color={color_template}
          title={item.title}
          text={item.text}
          fluid={item.background_image
            ? item.background_image.localFile.childImageSharp.fluid : null}
        />
      ))}
      <TestimonialSection slide={slide} style={{ paddingTop: '60px', paddingBottom: '189px' }} />
      <Slider
        static_slider_section_title={bottomSlideTitle}
        fluid={bottomSlideImage}
        static_image_slider={bottomSlide}
      />
    </>
  );
};

export const query = graphql`
  query($lang: String, $slug: String) {
    wordpressPage(
      slug: { eq: $slug }
      wpml_current_locale: { eq: $lang }
    ) {
      title 
      path
      yoast_head
      acf {
        color_template
        title
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
        article_with_background {
          title
          text
          background_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        article_without_background {
          title
          text
        }
        article_log {
          image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
          item_section {
            title
            text
            background_image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        testimonial_section {
          slide {
            title
            description2
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        slider_at_the_bottom {
          section_title
          section_image {
            localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          item_slider {
            description
            color_overlay
          }
        }
      }
    }
    wordpressAcfOptions {
    options {
      kontakt {
        de_label
        en_label
      }
      produkte {
        de_label
        en_label
      }
      referenzen {
        de_label
        en_label
      }
      sprache {
        de_label
        en_label
      }
      copyright_text
      contact_icons_footer {
        email
        phone
      }
      bottom_footer_right {
        name
        english_name
        url
      }
      bottom_footer_left {
        name
        english_name
        url
      }
    }
  }
  }
`;

export default CaseStudy;
